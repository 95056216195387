.gradient-btn {
  border-radius: 50px;
  padding: 15px 20px;
  font-family: Montserrat-Medium;
  font-size: 0.9em;
  cursor: pointer;
  width: 100%;
  background: linear-gradient(to right, #1e93fd, #3154c8);
  color: #fff;
}

.disconnect-btn {
  border-radius: 50px;
  padding: 15px 20px;
  font-family: Montserrat-Medium;
  font-size: 0.9em;
  cursor: pointer;
  width: 100%;
  background: #FA5D72;
  color: #fff;
}

.accounts-list {
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);
  border-radius: 10px;
  text-align: center;
}

.btn {
  border-radius: 50px;
  padding: 4px 6px;
  font-family: Montserrat-Medium;
  font-size: 0.6em;
  width: 100%;
  cursor: pointer;
  border: 2px solid #1e93fd;
  background: linear-gradient(to right, #1e93fd, #3154c8);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.edit-input {
  border: 1px solid #1e93fd;
  border-radius: 5px;
  height: 40px;
  padding-left: 10px;
}

